enum Locale {
    DE = 'de',
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    IT = 'it',
    PL = 'pl',
}

export enum CountryBlocMembershipCode {
    EU = 'EU',
}

export type Country = {
    countryCode: string;
    countryISO3: string;
    i18nNames: Record<Locale, string>;
    rootIddCode: string;
    blocMemberships: `${CountryBlocMembershipCode}`[];
};

export const italianSpeakingCountries: string[] = ['IT', 'SM', 'VA'];

export const frenchSpeakingCountries: string[] = ['FR', 'MC'];

export const spainSpeakingCountries: string[] = [
    'ES',
    'MX',
    'CO',
    'AR',
    'PE',
    'VE',
    'CL',
    'EC',
    'GT',
    'CU',
    'BO',
    'DO',
    'HN',
    'PY',
    'SV',
    'NI',
    'CR',
    'PA',
    'UY',
    'GQ',
];

export const germanSpeakingCountries: string[] = ['DE', 'AT', 'LI'];

export const polishSpeakingCountries: string[] = ['PL'];

export const countries: Country[] = [
    {
        countryCode: 'GS',
        countryISO3: 'SGS',
        i18nNames: {
            en: 'South Georgia',
            it: 'Georgia del Sud e Isole Sandwich Meridionali',
            es: 'Islas Georgias del Sur y Sandwich del Sur',
            de: 'Südgeorgien',
            pl: 'Georgia Południowa',
            fr: 'Géorgie du Sud et îles Sandwich du Sud',
        },
        rootIddCode: '+500',
        blocMemberships: [],
    },
    {
        countryCode: 'GD',
        countryISO3: 'GRD',
        i18nNames: {
            en: 'Grenada',
            it: 'Grenada',
            es: 'Granada',
            de: 'Grenada',
            pl: 'Grenada',
            fr: 'Grenade',
        },
        rootIddCode: '+1473',
        blocMemberships: [],
    },
    {
        countryCode: 'CH',
        countryISO3: 'CHE',
        i18nNames: {
            en: 'Switzerland',
            it: 'Svizzera',
            es: 'Suiza',
            de: 'Schweiz',
            pl: 'Szwajcaria',
            fr: 'Suisse',
        },
        rootIddCode: '+41',
        blocMemberships: [],
    },
    {
        countryCode: 'SL',
        countryISO3: 'SLE',
        i18nNames: {
            en: 'Sierra Leone',
            it: 'Sierra Leone',
            es: 'Sierra Leona',
            de: 'Sierra Leone',
            pl: 'Sierra Leone',
            fr: 'Sierra Leone',
        },
        rootIddCode: '+232',
        blocMemberships: [],
    },
    {
        countryCode: 'HU',
        countryISO3: 'HUN',
        i18nNames: {
            en: 'Hungary',
            it: 'Ungheria',
            es: 'Hungría',
            de: 'Ungarn',
            pl: 'Węgry',
            fr: 'Hongrie',
        },
        rootIddCode: '+36',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'TW',
        countryISO3: 'TWN',
        i18nNames: {
            en: 'Taiwan',
            it: 'Taiwan',
            es: 'Taiwán',
            de: 'Taiwan',
            pl: 'Tajwan',
            fr: 'Taïwan',
        },
        rootIddCode: '+886',
        blocMemberships: [],
    },
    {
        countryCode: 'WF',
        countryISO3: 'WLF',
        i18nNames: {
            en: 'Wallis and Futuna',
            es: 'Wallis y Futuna',
            it: 'Wallis e Futuna',
            de: 'Wallis und Futuna',
            pl: 'Wallis i Futuna',
            fr: 'Wallis-et-Futuna',
        },
        rootIddCode: '+681',
        blocMemberships: [],
    },
    {
        countryCode: 'BB',
        countryISO3: 'BRB',
        i18nNames: {
            en: 'Barbados',
            it: 'Barbados',
            es: 'Barbados',
            de: 'Barbados',
            pl: 'Barbados',
            fr: 'Barbade',
        },
        rootIddCode: '+1246',
        blocMemberships: [],
    },
    {
        countryCode: 'PN',
        countryISO3: 'PCN',
        i18nNames: {
            en: 'Pitcairn Islands',
            es: 'Islas Pitcairn',
            it: 'Isole Pitcairn',
            de: 'Pitcairninseln',
            pl: 'Wyspy Pitcairn',
            fr: 'Îles Pitcairn',
        },
        rootIddCode: '+64',
        blocMemberships: [],
    },
    {
        countryCode: 'CI',
        countryISO3: 'CIV',
        i18nNames: {
            en: 'Ivory Coast',
            it: "Costa d'Avorio",
            es: 'Costa de Marfil',
            de: 'Elfenbeinküste',
            pl: 'Wybrzeże Kości Słoniowej',
            fr: "Côte d'Ivoire",
        },
        rootIddCode: '+225',
        blocMemberships: [],
    },
    {
        countryCode: 'TN',
        countryISO3: 'TUN',
        i18nNames: {
            en: 'Tunisia',
            it: 'Tunisia',
            es: 'Túnez',
            de: 'Tunesien',
            pl: 'Tunezja',
            fr: 'Tunisie',
        },
        rootIddCode: '+216',
        blocMemberships: [],
    },
    {
        countryCode: 'IT',
        countryISO3: 'ITA',
        i18nNames: {
            en: 'Italy',
            it: 'Italia',
            es: 'Italia',
            de: 'Italien',
            pl: 'Włochy',
            fr: 'Italie',
        },
        rootIddCode: '+39',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'BJ',
        countryISO3: 'BEN',
        i18nNames: {
            en: 'Benin',
            it: 'Benin',
            es: 'Benín',
            de: 'Benin',
            pl: 'Benin',
            fr: 'Bénin',
        },
        rootIddCode: '+229',
        blocMemberships: [],
    },
    {
        countryCode: 'ID',
        countryISO3: 'IDN',
        i18nNames: {
            en: 'Indonesia',
            it: 'Indonesia',
            es: 'Indonesia',
            de: 'Indonesien',
            pl: 'Indonezja',
            fr: 'Indonésie',
        },
        rootIddCode: '+62',
        blocMemberships: [],
    },
    {
        countryCode: 'CV',
        countryISO3: 'CPV',
        i18nNames: {
            en: 'Cape Verde',
            it: 'Capo Verde',
            es: 'Cabo Verde',
            de: 'Kap Verde',
            pl: 'Republika Zielonego Przylądka',
            fr: 'Cap-Vert',
        },
        rootIddCode: '+238',
        blocMemberships: [],
    },
    {
        countryCode: 'KN',
        countryISO3: 'KNA',
        i18nNames: {
            en: 'Saint Kitts and Nevis',
            es: 'San Cristóbal y Nieves',
            it: 'Saint Kitts e Nevis',
            de: 'St. Kitts und Nevis',
            pl: 'Saint Kitts i Nevis',
            fr: 'Saint-Christophe-et-Niévès',
        },
        rootIddCode: '+1869',
        blocMemberships: [],
    },
    {
        countryCode: 'LA',
        countryISO3: 'LAO',
        i18nNames: {
            en: 'Laos',
            it: 'Laos',
            es: 'Laos',
            de: 'Laos',
            pl: 'Laos',
            fr: 'Laos',
        },
        rootIddCode: '+856',
        blocMemberships: [],
    },
    {
        countryCode: 'BQ',
        countryISO3: 'BES',
        i18nNames: {
            en: 'Caribbean Netherlands',
            es: 'Bonaire, San Eustaquio y Saba',
            it: 'Paesi Bassi caraibici',
            de: 'Karibische Niederlande',
            pl: 'Niderlandy Karaibskie',
            fr: 'Pays-Bas caribéens',
        },
        rootIddCode: '+599',
        blocMemberships: [],
    },
    {
        countryCode: 'UG',
        countryISO3: 'UGA',
        i18nNames: {
            en: 'Uganda',
            it: 'Uganda',
            es: 'Uganda',
            de: 'Uganda',
            pl: 'Uganda',
            fr: 'Ouganda',
        },
        rootIddCode: '+256',
        blocMemberships: [],
    },
    {
        countryCode: 'AD',
        countryISO3: 'AND',
        i18nNames: {
            en: 'Andorra',
            it: 'Andorra',
            es: 'Andorra',
            de: 'Andorra',
            pl: 'Andora',
            fr: 'Andorre',
        },
        rootIddCode: '+376',
        blocMemberships: [],
    },
    {
        countryCode: 'BI',
        countryISO3: 'BDI',
        i18nNames: {
            en: 'Burundi',
            it: 'Burundi',
            es: 'Burundi',
            de: 'Burundi',
            pl: 'Burundi',
            fr: 'Burundi',
        },
        rootIddCode: '+257',
        blocMemberships: [],
    },
    {
        countryCode: 'ZA',
        countryISO3: 'ZAF',
        i18nNames: {
            en: 'South Africa',
            it: 'Sud Africa',
            es: 'Sudáfrica',
            de: 'Südafrika',
            pl: 'Republika Południowej Afryki',
            fr: 'Afrique du Sud',
        },
        rootIddCode: '+27',
        blocMemberships: [],
    },
    {
        countryCode: 'FR',
        countryISO3: 'FRA',
        i18nNames: {
            en: 'France',
            it: 'Francia',
            es: 'Francia',
            de: 'Frankreich',
            pl: 'Francja',
            fr: 'France',
        },
        rootIddCode: '+33',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'LY',
        countryISO3: 'LBY',
        i18nNames: {
            en: 'Libya',
            it: 'Libia',
            es: 'Libia',
            de: 'Libyen',
            pl: 'Libia',
            fr: 'Libye',
        },
        rootIddCode: '+218',
        blocMemberships: [],
    },
    {
        countryCode: 'MX',
        countryISO3: 'MEX',
        i18nNames: {
            en: 'Mexico',
            it: 'Messico',
            es: 'México',
            de: 'Mexiko',
            pl: 'Meksyk',
            fr: 'Mexique',
        },
        rootIddCode: '+52',
        blocMemberships: [],
    },
    {
        countryCode: 'GA',
        countryISO3: 'GAB',
        i18nNames: {
            en: 'Gabon',
            it: 'Gabon',
            es: 'Gabón',
            de: 'Gabun',
            pl: 'Gabon',
            fr: 'Gabon',
        },
        rootIddCode: '+241',
        blocMemberships: [],
    },
    {
        countryCode: 'MP',
        countryISO3: 'MNP',
        i18nNames: {
            en: 'Northern Mariana Islands',
            es: 'Islas Marianas del Norte',
            it: 'Isole Marianne Settentrionali',
            de: 'Nördliche Marianen',
            pl: 'Mariany Północne',
            fr: 'Îles Mariannes du Nord',
        },
        rootIddCode: '+1670',
        blocMemberships: [],
    },
    {
        countryCode: 'MK',
        countryISO3: 'MKD',
        i18nNames: {
            en: 'North Macedonia',
            it: 'Macedonia del Nord',
            es: 'Macedonia del Norte',
            de: 'Nordmazedonien',
            pl: 'Macedonia Północna',
            fr: 'Macédoine du Nord',
        },
        rootIddCode: '+389',
        blocMemberships: [],
    },
    {
        countryCode: 'CN',
        countryISO3: 'CHN',
        i18nNames: {
            en: 'China',
            it: 'Cina',
            es: 'China',
            de: 'China',
            pl: 'Chiny',
            fr: 'Chine',
        },
        rootIddCode: '+86',
        blocMemberships: [],
    },
    {
        countryCode: 'YE',
        countryISO3: 'YEM',
        i18nNames: {
            en: 'Yemen',
            it: 'Yemen',
            es: 'Yemen',
            de: 'Jemen',
            pl: 'Jemen',
            fr: 'Yémen',
        },
        rootIddCode: '+967',
        blocMemberships: [],
    },
    {
        countryCode: 'BL',
        countryISO3: 'BLM',
        i18nNames: {
            en: 'Saint Barthélemy',
            es: 'San Bartolomé',
            it: 'Antille Francesi',
            de: 'Saint-Barthélemy',
            pl: 'Saint-Barthélemy',
            fr: 'Saint-Barthélemy',
        },
        rootIddCode: '+590',
        blocMemberships: [],
    },
    {
        countryCode: 'GG',
        countryISO3: 'GGY',
        i18nNames: {
            en: 'Guernsey',
            it: 'Guernsey',
            es: 'Guernsey',
            de: 'Guernsey',
            pl: 'Guernsey',
            fr: 'Guernesey',
        },
        rootIddCode: '+44',
        blocMemberships: [],
    },
    {
        countryCode: 'SB',
        countryISO3: 'SLB',
        i18nNames: {
            en: 'Solomon Islands',
            it: 'Isole Salomone',
            es: 'Islas Salomón',
            de: 'Salomonen',
            pl: 'Wyspy Salomona',
            fr: 'Îles Salomon',
        },
        rootIddCode: '+677',
        blocMemberships: [],
    },
    {
        countryCode: 'SJ',
        countryISO3: 'SJM',
        i18nNames: {
            en: 'Svalbard and Jan Mayen',
            es: 'Svalbard y Jan Mayen',
            it: 'Svalbard e Jan Mayen',
            de: 'Svalbard und Jan Mayen',
            pl: 'Svalbard i Jan Mayen',
            fr: 'Svalbard et Jan Mayen',
        },
        rootIddCode: '+4779',
        blocMemberships: [],
    },
    {
        countryCode: 'FO',
        countryISO3: 'FRO',
        i18nNames: {
            en: 'Faroe Islands',
            it: 'Isole Far Oer',
            es: 'Islas Feroe',
            de: 'Färöer-Inseln',
            pl: 'Wyspy Owcze',
            fr: 'Îles Féroé',
        },
        rootIddCode: '+298',
        blocMemberships: [],
    },
    {
        countryCode: 'UZ',
        countryISO3: 'UZB',
        i18nNames: {
            en: 'Uzbekistan',
            it: 'Uzbekistan',
            es: 'Uzbekistán',
            de: 'Usbekistan',
            pl: 'Uzbekistan',
            fr: 'Ouzbékistan',
        },
        rootIddCode: '+998',
        blocMemberships: [],
    },
    {
        countryCode: 'EG',
        countryISO3: 'EGY',
        i18nNames: {
            en: 'Egypt',
            it: 'Egitto',
            es: 'Egipto',
            de: 'Ägypten',
            pl: 'Egipt',
            fr: 'Égypte',
        },
        rootIddCode: '+20',
        blocMemberships: [],
    },
    {
        countryCode: 'SN',
        countryISO3: 'SEN',
        i18nNames: {
            en: 'Senegal',
            it: 'Senegal',
            es: 'Senegal',
            de: 'Senegal',
            pl: 'Senegal',
            fr: 'Sénégal',
        },
        rootIddCode: '+221',
        blocMemberships: [],
    },
    {
        countryCode: 'LK',
        countryISO3: 'LKA',
        i18nNames: {
            en: 'Sri Lanka',
            it: 'Sri Lanka',
            es: 'Sri Lanka',
            de: 'Sri Lanka',
            pl: 'Sri Lanka',
            fr: 'Sri Lanka',
        },
        rootIddCode: '+94',
        blocMemberships: [],
    },
    {
        countryCode: 'PS',
        countryISO3: 'PSE',
        i18nNames: {
            en: 'Palestine',
            it: 'Palestina',
            es: 'Palestina',
            de: 'Palästina',
            pl: 'Palestyna',
            fr: 'Palestine',
        },
        rootIddCode: '+970',
        blocMemberships: [],
    },
    {
        countryCode: 'BD',
        countryISO3: 'BGD',
        i18nNames: {
            en: 'Bangladesh',
            it: 'Bangladesh',
            es: 'Bangladés',
            de: 'Bangladesch',
            pl: 'Bangladesz',
            fr: 'Bangladesh',
        },
        rootIddCode: '+880',
        blocMemberships: [],
    },
    {
        countryCode: 'PE',
        countryISO3: 'PER',
        i18nNames: {
            en: 'Peru',
            it: 'Perù',
            es: 'Perú',
            de: 'Peru',
            pl: 'Peru',
            fr: 'Pérou',
        },
        rootIddCode: '+51',
        blocMemberships: [],
    },
    {
        countryCode: 'SG',
        countryISO3: 'SGP',
        i18nNames: {
            en: 'Singapore',
            it: 'Singapore',
            es: 'Singapur',
            de: 'Singapur',
            pl: 'Singapur',
            fr: 'Singapour',
        },
        rootIddCode: '+65',
        blocMemberships: [],
    },
    {
        countryCode: 'TR',
        countryISO3: 'TUR',
        i18nNames: {
            en: 'Turkey',
            it: 'Turchia',
            es: 'Turquía',
            de: 'Türkei',
            pl: 'Turcja',
            fr: 'Turquie',
        },
        rootIddCode: '+90',
        blocMemberships: [],
    },
    {
        countryCode: 'AF',
        countryISO3: 'AFG',
        i18nNames: {
            en: 'Afghanistan',
            it: 'Afghanistan',
            es: 'Afganistán',
            de: 'Afghanistan',
            pl: 'Afganistan',
            fr: 'Afghanistan',
        },
        rootIddCode: '+93',
        blocMemberships: [],
    },
    {
        countryCode: 'AW',
        countryISO3: 'ABW',
        i18nNames: {
            en: 'Aruba',
            it: 'Aruba',
            es: 'Aruba',
            de: 'Aruba',
            pl: 'Aruba',
            fr: 'Aruba',
        },
        rootIddCode: '+297',
        blocMemberships: [],
    },
    {
        countryCode: 'CK',
        countryISO3: 'COK',
        i18nNames: {
            en: 'Cook Islands',
            it: 'Isole Cook',
            es: 'Islas Cook',
            de: 'Cookinseln',
            pl: 'Wyspy Cooka',
            fr: 'Îles Cook',
        },
        rootIddCode: '+682',
        blocMemberships: [],
    },
    {
        countryCode: 'GB',
        countryISO3: 'GBR',
        i18nNames: {
            en: 'United Kingdom',
            it: 'Regno Unito',
            es: 'Reino Unido',
            de: 'Vereinigtes Königreich',
            pl: 'Zjednoczone Królestwo',
            fr: 'Royaume-Uni',
        },
        rootIddCode: '+44',
        blocMemberships: [],
    },
    {
        countryCode: 'ZM',
        countryISO3: 'ZMB',
        i18nNames: {
            en: 'Zambia',
            it: 'Zambia',
            es: 'Zambia',
            de: 'Sambia',
            pl: 'Zambia',
            fr: 'Zambie',
        },
        rootIddCode: '+260',
        blocMemberships: [],
    },
    {
        countryCode: 'FI',
        countryISO3: 'FIN',
        i18nNames: {
            en: 'Finland',
            it: 'Finlandia',
            es: 'Finlandia',
            de: 'Finnland',
            pl: 'Finlandia',
            fr: 'Finlande',
        },
        rootIddCode: '+358',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'NE',
        countryISO3: 'NER',
        i18nNames: {
            en: 'Niger',
            it: 'Niger',
            es: 'Níger',
            de: 'Niger',
            pl: 'Niger',
            fr: 'Niger',
        },
        rootIddCode: '+227',
        blocMemberships: [],
    },
    {
        countryCode: 'CX',
        countryISO3: 'CXR',
        i18nNames: {
            en: 'Christmas Island',
            es: 'Isla Christmas',
            it: 'Isola di Natale',
            de: 'Weihnachtsinsel',
            pl: 'Wyspa Bożego Narodzenia',
            fr: 'Île Christmas',
        },
        rootIddCode: '+61',
        blocMemberships: [],
    },
    {
        countryCode: 'TK',
        countryISO3: 'TKL',
        i18nNames: {
            en: 'Tokelau',
            it: 'Isole Tokelau',
            es: 'Tokelau',
            de: 'Tokelau',
            pl: 'Tokelau',
            fr: 'Tokelau',
        },
        rootIddCode: '+690',
        blocMemberships: [],
    },
    {
        countryCode: 'GW',
        countryISO3: 'GNB',
        i18nNames: {
            en: 'Guinea-Bissau',
            it: 'Guinea-Bissau',
            es: 'Guinea-Bisáu',
            de: 'Guinea-Bissau',
            pl: 'Gwinea-Bissau',
            fr: 'Guinée-Bissau',
        },
        rootIddCode: '+245',
        blocMemberships: [],
    },
    {
        countryCode: 'AZ',
        countryISO3: 'AZE',
        i18nNames: {
            en: 'Azerbaijan',
            it: 'Azerbaijan',
            es: 'Azerbaiyán',
            de: 'Aserbaidschan',
            pl: 'Azerbejdżan',
            fr: 'Azerbaïdjan',
        },
        rootIddCode: '+994',
        blocMemberships: [],
    },
    {
        countryCode: 'RE',
        countryISO3: 'REU',
        i18nNames: {
            en: 'Réunion',
            it: 'Riunione',
            es: 'Reunión',
            de: 'Réunion',
            pl: 'Reunion',
            fr: 'Réunion',
        },
        rootIddCode: '+262',
        blocMemberships: [],
    },
    {
        countryCode: 'DJ',
        countryISO3: 'DJI',
        i18nNames: {
            en: 'Djibouti',
            it: 'Gibuti',
            es: 'Yibuti',
            de: 'Dschibuti',
            pl: 'Dżibuti',
            fr: 'Djibouti',
        },
        rootIddCode: '+253',
        blocMemberships: [],
    },
    {
        countryCode: 'KP',
        countryISO3: 'PRK',
        i18nNames: {
            en: 'North Korea',
            it: 'Corea del Nord',
            es: 'Corea del Norte',
            de: 'Nordkorea',
            pl: 'Korea Północna',
            fr: 'Corée du Nord',
        },
        rootIddCode: '+850',
        blocMemberships: [],
    },
    {
        countryCode: 'MU',
        countryISO3: 'MUS',
        i18nNames: {
            en: 'Mauritius',
            it: 'Mauritius',
            es: 'Mauricio',
            de: 'Mauritius',
            pl: 'Mauritius',
            fr: 'Île Maurice',
        },
        rootIddCode: '+230',
        blocMemberships: [],
    },
    {
        countryCode: 'MS',
        countryISO3: 'MSR',
        i18nNames: {
            en: 'Montserrat',
            it: 'Montserrat',
            es: 'Montserrat',
            de: 'Montserrat',
            pl: 'Montserrat',
            fr: 'Montserrat',
        },
        rootIddCode: '+1664',
        blocMemberships: [],
    },
    {
        countryCode: 'VI',
        countryISO3: 'VIR',
        i18nNames: {
            en: 'United States Virgin Islands',
            es: 'Islas Vírgenes de los Estados Unidos',
            it: 'Isole Vergini americane',
            de: 'Amerikanische Jungferninseln',
            pl: 'Wyspy Dziewicze Stanów Zjednoczonych',
            fr: 'Îles Vierges des États-Unis',
        },
        rootIddCode: '+1340',
        blocMemberships: [],
    },
    {
        countryCode: 'CO',
        countryISO3: 'COL',
        i18nNames: {
            en: 'Colombia',
            it: 'Colombia',
            es: 'Colombia',
            de: 'Kolumbien',
            pl: 'Kolumbia',
            fr: 'Colombie',
        },
        rootIddCode: '+57',
        blocMemberships: [],
    },
    {
        countryCode: 'GR',
        countryISO3: 'GRC',
        i18nNames: {
            en: 'Greece',
            it: 'Grecia',
            es: 'Grecia',
            de: 'Griechenland',
            pl: 'Grecja',
            fr: 'Grèce',
        },
        rootIddCode: '+30',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'HR',
        countryISO3: 'HRV',
        i18nNames: {
            en: 'Croatia',
            it: 'Croazia',
            es: 'Croacia',
            de: 'Kroatien',
            pl: 'Chorwacja',
            fr: 'Croatie',
        },
        rootIddCode: '+385',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'MA',
        countryISO3: 'MAR',
        i18nNames: {
            en: 'Morocco',
            it: 'Marocco',
            es: 'Marruecos',
            de: 'Marokko',
            pl: 'Maroko',
            fr: 'Maroc',
        },
        rootIddCode: '+212',
        blocMemberships: [],
    },
    {
        countryCode: 'DZ',
        countryISO3: 'DZA',
        i18nNames: {
            en: 'Algeria',
            it: 'Algeria',
            es: 'Argelia',
            de: 'Algerien',
            pl: 'Algieria',
            fr: 'Algérie',
        },
        rootIddCode: '+213',
        blocMemberships: [],
    },
    {
        countryCode: 'AQ',
        countryISO3: 'ATA',
        i18nNames: {
            en: 'Antarctica',
            it: 'Antartide',
            es: 'Antártida',
            de: 'Antarktis',
            pl: 'Antarktyka',
            fr: 'Antarctique',
        },
        rootIddCode: 'undefined',
        blocMemberships: [],
    },
    {
        countryCode: 'NL',
        countryISO3: 'NLD',
        i18nNames: {
            en: 'Netherlands',
            it: 'Paesi Bassi',
            es: 'Países Bajos',
            de: 'Niederlande',
            pl: 'Holandia',
            fr: 'Pays-Bas',
        },
        rootIddCode: '+31',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'SD',
        countryISO3: 'SDN',
        i18nNames: {
            en: 'Sudan',
            it: 'Sudan',
            es: 'Sudán',
            de: 'Sudan',
            pl: 'Sudan',
            fr: 'Soudan',
        },
        rootIddCode: '+249',
        blocMemberships: [],
    },
    {
        countryCode: 'FJ',
        countryISO3: 'FJI',
        i18nNames: {
            en: 'Fiji',
            it: 'Figi',
            es: 'Fiyi',
            de: 'Fidschi',
            pl: 'Fidżi',
            fr: 'Fidji',
        },
        rootIddCode: '+679',
        blocMemberships: [],
    },
    {
        countryCode: 'LI',
        countryISO3: 'LIE',
        i18nNames: {
            en: 'Liechtenstein',
            it: 'Liechtenstein',
            es: 'Liechtenstein',
            de: 'Liechtenstein',
            pl: 'Liechtenstein',
            fr: 'Liechtenstein',
        },
        rootIddCode: '+423',
        blocMemberships: [],
    },
    {
        countryCode: 'NP',
        countryISO3: 'NPL',
        i18nNames: {
            en: 'Nepal',
            it: 'Nepal',
            es: 'Nepal',
            de: 'Nepal',
            pl: 'Nepal',
            fr: 'Népal',
        },
        rootIddCode: '+977',
        blocMemberships: [],
    },
    {
        countryCode: 'PR',
        countryISO3: 'PRI',
        i18nNames: {
            en: 'Puerto Rico',
            it: 'Porto Rico',
            es: 'Puerto Rico',
            de: 'Puerto Rico',
            pl: 'Portoryko',
            fr: 'Porto Rico',
        },
        rootIddCode: '+1939',
        blocMemberships: [],
    },
    {
        countryCode: 'GE',
        countryISO3: 'GEO',
        i18nNames: {
            en: 'Georgia',
            it: 'Georgia',
            es: 'Georgia',
            de: 'Georgien',
            pl: 'Gruzja',
            fr: 'Géorgie',
        },
        rootIddCode: '+995',
        blocMemberships: [],
    },
    {
        countryCode: 'PK',
        countryISO3: 'PAK',
        i18nNames: {
            en: 'Pakistan',
            it: 'Pakistan',
            es: 'Pakistán',
            de: 'Pakistan',
            pl: 'Pakistan',
            fr: 'Pakistan',
        },
        rootIddCode: '+92',
        blocMemberships: [],
    },
    {
        countryCode: 'MC',
        countryISO3: 'MCO',
        i18nNames: {
            en: 'Monaco',
            it: 'Principato di Monaco',
            es: 'Mónaco',
            de: 'Monaco',
            pl: 'Monako',
            fr: 'Monaco',
        },
        rootIddCode: '+377',
        blocMemberships: [],
    },
    {
        countryCode: 'BW',
        countryISO3: 'BWA',
        i18nNames: {
            en: 'Botswana',
            it: 'Botswana',
            es: 'Botsuana',
            de: 'Botswana',
            pl: 'Botswana',
            fr: 'Botswana',
        },
        rootIddCode: '+267',
        blocMemberships: [],
    },
    {
        countryCode: 'LB',
        countryISO3: 'LBN',
        i18nNames: {
            en: 'Lebanon',
            it: 'Libano',
            es: 'Líbano',
            de: 'Libanon',
            pl: 'Liban',
            fr: 'Liban',
        },
        rootIddCode: '+961',
        blocMemberships: [],
    },
    {
        countryCode: 'PG',
        countryISO3: 'PNG',
        i18nNames: {
            en: 'Papua New Guinea',
            es: 'Papúa Nueva Guinea',
            it: 'Papua Nuova Guinea',
            de: 'Papua-Neuguinea',
            pl: 'Papua-Nowa Gwinea',
            fr: 'Papouasie-Nouvelle-Guinée',
        },
        rootIddCode: '+675',
        blocMemberships: [],
    },
    {
        countryCode: 'YT',
        countryISO3: 'MYT',
        i18nNames: {
            en: 'Mayotte',
            it: 'Mayotte',
            es: 'Mayotte',
            de: 'Mayotte',
            pl: 'Majotta',
            fr: 'Mayotte',
        },
        rootIddCode: '+262',
        blocMemberships: [],
    },
    {
        countryCode: 'DO',
        countryISO3: 'DOM',
        i18nNames: {
            en: 'Dominican Republic',
            es: 'República Dominicana',
            it: 'Repubblica Dominicana',
            de: 'Dominikanische Republik',
            pl: 'Dominikana',
            fr: 'République dominicaine',
        },
        rootIddCode: '+1849',
        blocMemberships: [],
    },
    {
        countryCode: 'NF',
        countryISO3: 'NFK',
        i18nNames: {
            en: 'Norfolk Island',
            it: 'Isola Norfolk',
            es: 'Isla Norfolk',
            de: 'Norfolkinsel',
            pl: 'Wyspa Norfolk',
            fr: 'Île Norfolk',
        },
        rootIddCode: '+672',
        blocMemberships: [],
    },
    {
        countryCode: 'BV',
        countryISO3: 'BVT',
        i18nNames: {
            en: 'Bouvet Island',
            it: 'Isola Bouvet',
            es: 'Isla Bouvet',
            de: 'Bouvetinsel',
            pl: 'Wyspa Bouveta',
            fr: 'Île Bouvet',
        },
        rootIddCode: '+47',
        blocMemberships: [],
    },
    {
        countryCode: 'QA',
        countryISO3: 'QAT',
        i18nNames: {
            en: 'Qatar',
            it: 'Qatar',
            es: 'Catar',
            de: 'Katar',
            pl: 'Katar',
            fr: 'Qatar',
        },
        rootIddCode: '+974',
        blocMemberships: [],
    },
    {
        countryCode: 'MG',
        countryISO3: 'MDG',
        i18nNames: {
            en: 'Madagascar',
            it: 'Madagascar',
            es: 'Madagascar',
            de: 'Madagaskar',
            pl: 'Madagaskar',
            fr: 'Madagascar',
        },
        rootIddCode: '+261',
        blocMemberships: [],
    },
    {
        countryCode: 'IN',
        countryISO3: 'IND',
        i18nNames: {
            en: 'India',
            it: 'India',
            es: 'India',
            de: 'Indien',
            pl: 'Indie',
            fr: 'Inde',
        },
        rootIddCode: '+91',
        blocMemberships: [],
    },
    {
        countryCode: 'SY',
        countryISO3: 'SYR',
        i18nNames: {
            en: 'Syria',
            it: 'Siria',
            es: 'Siria',
            de: 'Syrien',
            pl: 'Syria',
            fr: 'Syrie',
        },
        rootIddCode: '+963',
        blocMemberships: [],
    },
    {
        countryCode: 'ME',
        countryISO3: 'MNE',
        i18nNames: {
            en: 'Montenegro',
            it: 'Montenegro',
            es: 'Montenegro',
            de: 'Montenegro',
            pl: 'Czarnogóra',
            fr: 'Monténégro',
        },
        rootIddCode: '+382',
        blocMemberships: [],
    },
    {
        countryCode: 'SZ',
        countryISO3: 'SWZ',
        i18nNames: {
            en: 'Eswatini',
            it: 'Swaziland',
            es: 'Suazilandia',
            de: 'Eswatini',
            pl: 'Eswatini',
            fr: 'Eswatini',
        },
        rootIddCode: '+268',
        blocMemberships: [],
    },
    {
        countryCode: 'PY',
        countryISO3: 'PRY',
        i18nNames: {
            en: 'Paraguay',
            it: 'Paraguay',
            es: 'Paraguay',
            de: 'Paraguay',
            pl: 'Paragwaj',
            fr: 'Paraguay',
        },
        rootIddCode: '+595',
        blocMemberships: [],
    },
    {
        countryCode: 'SV',
        countryISO3: 'SLV',
        i18nNames: {
            en: 'El Salvador',
            it: 'El Salvador',
            es: 'El Salvador',
            de: 'El Salvador',
            pl: 'Salwador',
            fr: 'Le Salvador',
        },
        rootIddCode: '+503',
        blocMemberships: [],
    },
    {
        countryCode: 'UA',
        countryISO3: 'UKR',
        i18nNames: {
            en: 'Ukraine',
            it: 'Ucraina',
            es: 'Ucrania',
            de: 'Ukraine',
            pl: 'Ukraina',
            fr: 'Ukraine',
        },
        rootIddCode: '+380',
        blocMemberships: [],
    },
    {
        countryCode: 'IM',
        countryISO3: 'IMN',
        i18nNames: {
            en: 'Isle of Man',
            it: 'Isola di Man',
            es: 'Isla de Man',
            de: 'Isle of Man',
            pl: 'Wyspa Man',
            fr: 'Île de Man',
        },
        rootIddCode: '+44',
        blocMemberships: [],
    },
    {
        countryCode: 'NA',
        countryISO3: 'NAM',
        i18nNames: {
            en: 'Namibia',
            it: 'Namibia',
            es: 'Namibia',
            de: 'Namibia',
            pl: 'Namibia',
            fr: 'Namibie',
        },
        rootIddCode: '+264',
        blocMemberships: [],
    },
    {
        countryCode: 'AE',
        countryISO3: 'ARE',
        i18nNames: {
            en: 'United Arab Emirates',
            es: 'Emiratos Árabes Unidos',
            it: 'Emirati Arabi Uniti',
            de: 'Vereinigte Arabische Emirate',
            pl: 'Zjednoczone Emiraty Arabskie',
            fr: 'Émirats arabes unis',
        },
        rootIddCode: '+971',
        blocMemberships: [],
    },
    {
        countryCode: 'BG',
        countryISO3: 'BGR',
        i18nNames: {
            en: 'Bulgaria',
            it: 'Bulgaria',
            es: 'Bulgaria',
            de: 'Bulgarien',
            pl: 'Bułgaria',
            fr: 'Bulgarie',
        },
        rootIddCode: '+359',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'GL',
        countryISO3: 'GRL',
        i18nNames: {
            en: 'Greenland',
            it: 'Groenlandia',
            es: 'Groenlandia',
            de: 'Grönland',
            pl: 'Grenlandia',
            fr: 'Groenland',
        },
        rootIddCode: '+299',
        blocMemberships: [],
    },
    {
        countryCode: 'DE',
        countryISO3: 'DEU',
        i18nNames: {
            en: 'Germany',
            it: 'Germania',
            es: 'Alemania',
            de: 'Deutschland',
            pl: 'Niemcy',
            fr: 'Allemagne',
        },
        rootIddCode: '+49',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'KH',
        countryISO3: 'KHM',
        i18nNames: {
            en: 'Cambodia',
            it: 'Cambogia',
            es: 'Camboya',
            de: 'Kambodscha',
            pl: 'Kambodża',
            fr: 'Cambodge',
        },
        rootIddCode: '+855',
        blocMemberships: [],
    },
    {
        countryCode: 'IQ',
        countryISO3: 'IRQ',
        i18nNames: {
            en: 'Iraq',
            it: 'Iraq',
            es: 'Irak',
            de: 'Irak',
            pl: 'Irak',
            fr: 'Irak',
        },
        rootIddCode: '+964',
        blocMemberships: [],
    },
    {
        countryCode: 'TF',
        countryISO3: 'ATF',
        i18nNames: {
            en: 'French Southern and Antarctic Lands',
            es: 'Territorios Australes Franceses',
            it: 'Territori Francesi del Sud',
            de: 'Französische Süd- und Antarktisgebiete',
            pl: 'Francuskie Terytoria Południowe i Antarktyczne',
            fr: 'Terres australes et antarctiques françaises',
        },
        rootIddCode: '+262',
        blocMemberships: [],
    },
    {
        countryCode: 'SE',
        countryISO3: 'SWE',
        i18nNames: {
            en: 'Sweden',
            it: 'Svezia',
            es: 'Suecia',
            de: 'Schweden',
            pl: 'Szwecja',
            fr: 'Suède',
        },
        rootIddCode: '+46',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'CU',
        countryISO3: 'CUB',
        i18nNames: {
            en: 'Cuba',
            it: 'Cuba',
            es: 'Cuba',
            de: 'Kuba',
            pl: 'Kuba',
            fr: 'Cuba',
        },
        rootIddCode: '+53',
        blocMemberships: [],
    },
    {
        countryCode: 'KG',
        countryISO3: 'KGZ',
        i18nNames: {
            en: 'Kyrgyzstan',
            it: 'Kirghizistan',
            es: 'Kirguistán',
            de: 'Kirgisistan',
            pl: 'Kirgistan',
            fr: 'Kirghizistan',
        },
        rootIddCode: '+996',
        blocMemberships: [],
    },
    {
        countryCode: 'RU',
        countryISO3: 'RUS',
        i18nNames: {
            en: 'Russia',
            it: 'Russia',
            es: 'Rusia',
            de: 'Russland',
            pl: 'Rosja',
            fr: 'Russie',
        },
        rootIddCode: '+79',
        blocMemberships: [],
    },
    {
        countryCode: 'MY',
        countryISO3: 'MYS',
        i18nNames: {
            en: 'Malaysia',
            it: 'Malesia',
            es: 'Malasia',
            de: 'Malaysia',
            pl: 'Malezja',
            fr: 'Malaisie',
        },
        rootIddCode: '+60',
        blocMemberships: [],
    },
    {
        countryCode: 'ST',
        countryISO3: 'STP',
        i18nNames: {
            en: 'São Tomé and Príncipe',
            es: 'Santo Tomé y Príncipe',
            it: 'São Tomé e Príncipe',
            de: 'São Tomé und Príncipe',
            pl: 'Wyspy Świętego Tomasza i Książęca',
            fr: 'Sao Tomé-et-Principe',
        },
        rootIddCode: '+239',
        blocMemberships: [],
    },
    {
        countryCode: 'CY',
        countryISO3: 'CYP',
        i18nNames: {
            en: 'Cyprus',
            it: 'Cipro',
            es: 'Chipre',
            de: 'Zypern',
            pl: 'Cypr',
            fr: 'Chypre',
        },
        rootIddCode: '+357',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'CA',
        countryISO3: 'CAN',
        i18nNames: {
            en: 'Canada',
            it: 'Canada',
            es: 'Canadá',
            de: 'Kanada',
            pl: 'Kanada',
            fr: 'Canada',
        },
        rootIddCode: '+1',
        blocMemberships: [],
    },
    {
        countryCode: 'MW',
        countryISO3: 'MWI',
        i18nNames: {
            en: 'Malawi',
            it: 'Malawi',
            es: 'Malaui',
            de: 'Malawi',
            pl: 'Malawi',
            fr: 'Malawi',
        },
        rootIddCode: '+265',
        blocMemberships: [],
    },
    {
        countryCode: 'SA',
        countryISO3: 'SAU',
        i18nNames: {
            en: 'Saudi Arabia',
            it: 'Arabia Saudita',
            es: 'Arabia Saudita',
            de: 'Saudi-Arabien',
            pl: 'Arabia Saudyjska',
            fr: 'Arabie Saoudite',
        },
        rootIddCode: '+966',
        blocMemberships: [],
    },
    {
        countryCode: 'BA',
        countryISO3: 'BIH',
        i18nNames: {
            en: 'Bosnia and Herzegovina',
            es: 'Bosnia y Herzegovina',
            it: 'Bosnia ed Erzegovina',
            de: 'Bosnien und Herzegowina',
            pl: 'Bośnia i Hercegowina',
            fr: 'Bosnie-Herzégovine',
        },
        rootIddCode: '+387',
        blocMemberships: [],
    },
    {
        countryCode: 'ET',
        countryISO3: 'ETH',
        i18nNames: {
            en: 'Ethiopia',
            it: 'Etiopia',
            es: 'Etiopía',
            de: 'Äthiopien',
            pl: 'Etiopia',
            fr: 'Éthiopie',
        },
        rootIddCode: '+251',
        blocMemberships: [],
    },
    {
        countryCode: 'ES',
        countryISO3: 'ESP',
        i18nNames: {
            en: 'Spain',
            it: 'Spagna',
            es: 'España',
            de: 'Spanien',
            pl: 'Hiszpania',
            fr: 'Espagne',
        },
        rootIddCode: '+34',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'SI',
        countryISO3: 'SVN',
        i18nNames: {
            en: 'Slovenia',
            it: 'Slovenia',
            es: 'Eslovenia',
            de: 'Slowenien',
            pl: 'Słowenia',
            fr: 'Slovénie',
        },
        rootIddCode: '+386',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'OM',
        countryISO3: 'OMN',
        i18nNames: {
            en: 'Oman',
            it: 'Oman',
            es: 'Omán',
            de: 'Oman',
            pl: 'Oman',
            fr: 'Oman',
        },
        rootIddCode: '+968',
        blocMemberships: [],
    },
    {
        countryCode: 'PM',
        countryISO3: 'SPM',
        i18nNames: {
            en: 'Saint Pierre and Miquelon',
            es: 'San Pedro y Miquelón',
            it: 'Saint-Pierre e Miquelon',
            de: 'Saint-Pierre und Miquelon',
            pl: 'Saint-Pierre i Miquelon',
            fr: 'Saint-Pierre-et-Miquelon',
        },
        rootIddCode: '+508',
        blocMemberships: [],
    },
    {
        countryCode: 'MO',
        countryISO3: 'MAC',
        i18nNames: {
            en: 'Macau',
            it: 'Macao',
            es: 'Macao',
            de: 'Macau',
            pl: 'Makau',
            fr: 'Macao',
        },
        rootIddCode: '+853',
        blocMemberships: [],
    },
    {
        countryCode: 'SM',
        countryISO3: 'SMR',
        i18nNames: {
            en: 'San Marino',
            it: 'San Marino',
            es: 'San Marino',
            de: 'San Marino',
            pl: 'San Marino',
            fr: 'Saint-Marin',
        },
        rootIddCode: '+378',
        blocMemberships: [],
    },
    {
        countryCode: 'LS',
        countryISO3: 'LSO',
        i18nNames: {
            en: 'Lesotho',
            it: 'Lesotho',
            es: 'Lesoto',
            de: 'Lesotho',
            pl: 'Lesotho',
            fr: 'Lesotho',
        },
        rootIddCode: '+266',
        blocMemberships: [],
    },
    {
        countryCode: 'MH',
        countryISO3: 'MHL',
        i18nNames: {
            en: 'Marshall Islands',
            es: 'Islas Marshall',
            it: 'Isole Marshall',
            de: 'Marshallinseln',
            pl: 'Wyspy Marshalla',
            fr: 'Îles Marshall',
        },
        rootIddCode: '+692',
        blocMemberships: [],
    },
    {
        countryCode: 'SX',
        countryISO3: 'SXM',
        i18nNames: {
            en: 'Sint Maarten',
            it: 'Sint Maarten',
            es: 'San Martín (parte neerlandesa)',
            de: 'Sint Maarten',
            pl: 'Sint Maarten',
            fr: 'Saint-Martin (partie néerlandaise)',
        },
        rootIddCode: '+1721',
        blocMemberships: [],
    },
    {
        countryCode: 'IS',
        countryISO3: 'ISL',
        i18nNames: {
            en: 'Iceland',
            it: 'Islanda',
            es: 'Islandia',
            de: 'Island',
            pl: 'Islandia',
            fr: 'Islande',
        },
        rootIddCode: '+354',
        blocMemberships: [],
    },
    {
        countryCode: 'LU',
        countryISO3: 'LUX',
        i18nNames: {
            en: 'Luxembourg',
            it: 'Lussemburgo',
            es: 'Luxemburgo',
            de: 'Luxemburg',
            pl: 'Luksemburg',
            fr: 'Luxembourg',
        },
        rootIddCode: '+352',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'AR',
        countryISO3: 'ARG',
        i18nNames: {
            en: 'Argentina',
            it: 'Argentina',
            es: 'Argentina',
            de: 'Argentinien',
            pl: 'Argentyna',
            fr: 'Argentine',
        },
        rootIddCode: '+54',
        blocMemberships: [],
    },
    {
        countryCode: 'TC',
        countryISO3: 'TCA',
        i18nNames: {
            en: 'Turks and Caicos Islands',
            es: 'Islas Turcas y Caicos',
            it: 'Isole Turks e Caicos',
            de: 'Turks- und Caicosinseln',
            pl: 'Turks i Caicos',
            fr: 'Îles Turques-et-Caïques',
        },
        rootIddCode: '+1649',
        blocMemberships: [],
    },
    {
        countryCode: 'NR',
        countryISO3: 'NRU',
        i18nNames: {
            en: 'Nauru',
            it: 'Nauru',
            es: 'Nauru',
            de: 'Nauru',
            pl: 'Nauru',
            fr: 'Nauru',
        },
        rootIddCode: '+674',
        blocMemberships: [],
    },
    {
        countryCode: 'CC',
        countryISO3: 'CCK',
        i18nNames: {
            en: 'Cocos (Keeling) Islands',
            es: 'Islas Cocos',
            it: 'Isole Cocos e Keeling',
            de: 'Kokosinseln',
            pl: 'Wyspy Kokosowe',
            fr: 'Îles Cocos',
        },
        rootIddCode: '+61',
        blocMemberships: [],
    },
    {
        countryCode: 'EH',
        countryISO3: 'ESH',
        i18nNames: {
            en: 'Western Sahara',
            it: 'Sahara Occidentale',
            es: 'Sáhara Occidental',
            de: 'Westsahara',
            pl: 'Sahara Zachodnia',
            fr: 'Sahara occidental',
        },
        rootIddCode: '+2125289',
        blocMemberships: [],
    },
    {
        countryCode: 'DM',
        countryISO3: 'DMA',
        i18nNames: {
            en: 'Dominica',
            it: 'Dominica',
            es: 'Dominica',
            de: 'Dominica',
            pl: 'Dominika',
            fr: 'Dominique',
        },
        rootIddCode: '+1767',
        blocMemberships: [],
    },
    {
        countryCode: 'CR',
        countryISO3: 'CRI',
        i18nNames: {
            en: 'Costa Rica',
            it: 'Costa Rica',
            es: 'Costa Rica',
            de: 'Costa Rica',
            pl: 'Kostaryka',
            fr: 'Costa Rica',
        },
        rootIddCode: '+506',
        blocMemberships: [],
    },
    {
        countryCode: 'AU',
        countryISO3: 'AUS',
        i18nNames: {
            en: 'Australia',
            it: 'Australia',
            es: 'Australia',
            de: 'Australien',
            pl: 'Australia',
            fr: 'Australie',
        },
        rootIddCode: '+61',
        blocMemberships: [],
    },
    {
        countryCode: 'TH',
        countryISO3: 'THA',
        i18nNames: {
            en: 'Thailand',
            it: 'Tailandia',
            es: 'Tailandia',
            de: 'Thailand',
            pl: 'Tajlandia',
            fr: 'Thaïlande',
        },
        rootIddCode: '+66',
        blocMemberships: [],
    },
    {
        countryCode: 'HT',
        countryISO3: 'HTI',
        i18nNames: {
            en: 'Haiti',
            it: 'Haiti',
            es: 'Haití',
            de: 'Haiti',
            pl: 'Haiti',
            fr: 'Haïti',
        },
        rootIddCode: '+509',
        blocMemberships: [],
    },
    {
        countryCode: 'TV',
        countryISO3: 'TUV',
        i18nNames: {
            en: 'Tuvalu',
            it: 'Tuvalu',
            es: 'Tuvalu',
            de: 'Tuvalu',
            pl: 'Tuvalu',
            fr: 'Tuvalu',
        },
        rootIddCode: '+688',
        blocMemberships: [],
    },
    {
        countryCode: 'HN',
        countryISO3: 'HND',
        i18nNames: {
            en: 'Honduras',
            it: 'Honduras',
            es: 'Honduras',
            de: 'Honduras',
            pl: 'Honduras',
            fr: 'Honduras',
        },
        rootIddCode: '+504',
        blocMemberships: [],
    },
    {
        countryCode: 'GQ',
        countryISO3: 'GNQ',
        i18nNames: {
            en: 'Equatorial Guinea',
            es: 'Guinea Ecuatorial',
            it: 'Guinea Equatoriale',
            de: 'Äquatorialguinea',
            pl: 'Gwinea Równikowa',
            fr: 'Guinée équatoriale',
        },
        rootIddCode: '+240',
        blocMemberships: [],
    },
    {
        countryCode: 'LC',
        countryISO3: 'LCA',
        i18nNames: {
            en: 'Saint Lucia',
            it: 'Santa Lucia',
            es: 'Santa Lucía',
            de: 'St. Lucia',
            pl: 'Saint Lucia',
            fr: 'Sainte-Lucie',
        },
        rootIddCode: '+1758',
        blocMemberships: [],
    },
    {
        countryCode: 'PF',
        countryISO3: 'PYF',
        i18nNames: {
            en: 'French Polynesia',
            es: 'Polinesia Francesa',
            it: 'Polinesia Francese',
            de: 'Französisch-Polynesien',
            pl: 'Polinezja Francuska',
            fr: 'Polynésie française',
        },
        rootIddCode: '+689',
        blocMemberships: [],
    },
    {
        countryCode: 'BY',
        countryISO3: 'BLR',
        i18nNames: {
            en: 'Belarus',
            it: 'Bielorussia',
            es: 'Bielorrusia',
            de: 'Weißrussland',
            pl: 'Białoruś',
            fr: 'Biélorussie',
        },
        rootIddCode: '+375',
        blocMemberships: [],
    },
    {
        countryCode: 'LV',
        countryISO3: 'LVA',
        i18nNames: {
            en: 'Latvia',
            it: 'Lettonia',
            es: 'Letonia',
            de: 'Lettland',
            pl: 'Łotwa',
            fr: 'Lettonie',
        },
        rootIddCode: '+371',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'PW',
        countryISO3: 'PLW',
        i18nNames: {
            en: 'Palau',
            it: 'Palau',
            es: 'Palaos',
            de: 'Palau',
            pl: 'Palau',
            fr: 'Palaos',
        },
        rootIddCode: '+680',
        blocMemberships: [],
    },
    {
        countryCode: 'GP',
        countryISO3: 'GLP',
        i18nNames: {
            en: 'Guadeloupe',
            it: 'Guadeloupa',
            es: 'Guadalupe',
            de: 'Guadeloupe',
            pl: 'Gwadelupa',
            fr: 'Guadeloupe',
        },
        rootIddCode: '+590',
        blocMemberships: [],
    },
    {
        countryCode: 'PH',
        countryISO3: 'PHL',
        i18nNames: {
            en: 'Philippines',
            it: 'Filippine',
            es: 'Filipinas',
            de: 'Philippinen',
            pl: 'Filipiny',
            fr: 'Philippines',
        },
        rootIddCode: '+63',
        blocMemberships: [],
    },
    {
        countryCode: 'GI',
        countryISO3: 'GIB',
        i18nNames: {
            en: 'Gibraltar',
            it: 'Gibilterra',
            es: 'Gibraltar',
            de: 'Gibraltar',
            pl: 'Gibraltar',
            fr: 'Gibraltar',
        },
        rootIddCode: '+350',
        blocMemberships: [],
    },
    {
        countryCode: 'DK',
        countryISO3: 'DNK',
        i18nNames: {
            en: 'Denmark',
            it: 'Danimarca',
            es: 'Dinamarca',
            de: 'Dänemark',
            pl: 'Dania',
            fr: 'Danemark',
        },
        rootIddCode: '+45',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'CM',
        countryISO3: 'CMR',
        i18nNames: {
            en: 'Cameroon',
            it: 'Camerun',
            es: 'Camerún',
            de: 'Kamerun',
            pl: 'Kamerun',
            fr: 'Cameroun',
        },
        rootIddCode: '+237',
        blocMemberships: [],
    },
    {
        countryCode: 'GN',
        countryISO3: 'GIN',
        i18nNames: {
            en: 'Guinea',
            it: 'Guinea',
            es: 'Guinea',
            de: 'Guinea',
            pl: 'Gwinea',
            fr: 'Guinée',
        },
        rootIddCode: '+224',
        blocMemberships: [],
    },
    {
        countryCode: 'BH',
        countryISO3: 'BHR',
        i18nNames: {
            en: 'Bahrain',
            it: 'Bahrein',
            es: 'Baréin',
            de: 'Bahrain',
            pl: 'Bahrajn',
            fr: 'Bahreïn',
        },
        rootIddCode: '+973',
        blocMemberships: [],
    },
    {
        countryCode: 'SR',
        countryISO3: 'SUR',
        i18nNames: {
            en: 'Suriname',
            it: 'Suriname',
            es: 'Surinam',
            de: 'Suriname',
            pl: 'Surinam',
            fr: 'Suriname',
        },
        rootIddCode: '+597',
        blocMemberships: [],
    },
    {
        countryCode: 'CD',
        countryISO3: 'COD',
        i18nNames: {
            en: 'DR Congo',
            it: 'Congo (Rep. Dem.)',
            es: 'República Democrática del Congo',
            de: 'Demokratische Republik Kongo',
            pl: 'Demokratyczna Republika Konga',
            fr: 'RDC (République Démocratique du Congo)',
        },
        rootIddCode: '+243',
        blocMemberships: [],
    },
    {
        countryCode: 'SO',
        countryISO3: 'SOM',
        i18nNames: {
            en: 'Somalia',
            it: 'Somalia',
            es: 'Somalia',
            de: 'Somalia',
            pl: 'Somalia',
            fr: 'Somalie',
        },
        rootIddCode: '+252',
        blocMemberships: [],
    },
    {
        countryCode: 'CZ',
        countryISO3: 'CZE',
        i18nNames: {
            en: 'Czechia',
            it: 'Cechia',
            es: 'Chequia',
            de: 'Tschechien',
            pl: 'Czechy',
            fr: 'Tchéquie',
        },
        rootIddCode: '+420',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'NC',
        countryISO3: 'NCL',
        i18nNames: {
            en: 'New Caledonia',
            it: 'Nuova Caledonia',
            es: 'Nueva Caledonia',
            de: 'Neukaledonien',
            pl: 'Nowa Kaledonia',
            fr: 'Nouvelle-Calédonie',
        },
        rootIddCode: '+687',
        blocMemberships: [],
    },
    {
        countryCode: 'VU',
        countryISO3: 'VUT',
        i18nNames: {
            en: 'Vanuatu',
            it: 'Vanuatu',
            es: 'Vanuatu',
            de: 'Vanuatu',
            pl: 'Vanuatu',
            fr: 'Vanuatu',
        },
        rootIddCode: '+678',
        blocMemberships: [],
    },
    {
        countryCode: 'SH',
        countryISO3: 'SHN',
        i18nNames: {
            en: 'Saint Helena, Ascension and Tristan da Cunha',
            es: 'Santa Elena',
            it: "Sant'Elena, Ascensione e Tristan da Cunha",
            de: 'Sankt Helena, Ascension und Tristan da Cunha',
            pl: 'Wyspa Świętej Heleny, Wniebowstąpienia i Tristan da Cunha',
            fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
        },
        rootIddCode: '+247',
        blocMemberships: [],
    },
    {
        countryCode: 'TG',
        countryISO3: 'TGO',
        i18nNames: {
            en: 'Togo',
            it: 'Togo',
            es: 'Togo',
            de: 'Togo',
            pl: 'Togo',
            fr: 'Togo',
        },
        rootIddCode: '+228',
        blocMemberships: [],
    },
    {
        countryCode: 'VG',
        countryISO3: 'VGB',
        i18nNames: {
            en: 'British Virgin Islands',
            es: 'Islas Vírgenes Británicas',
            it: 'Isole Vergini Britanniche',
            de: 'Britische Jungferninseln',
            pl: 'Brytyjskie Wyspy Dziewicze',
            fr: 'Îles Vierges britanniques',
        },
        rootIddCode: '+1284',
        blocMemberships: [],
    },
    {
        countryCode: 'KE',
        countryISO3: 'KEN',
        i18nNames: {
            en: 'Kenya',
            it: 'Kenya',
            es: 'Kenia',
            de: 'Kenia',
            pl: 'Kenia',
            fr: 'Kenya',
        },
        rootIddCode: '+254',
        blocMemberships: [],
    },
    {
        countryCode: 'NU',
        countryISO3: 'NIU',
        i18nNames: {
            en: 'Niue',
            it: 'Niue',
            es: 'Niue',
            de: 'Niue',
            pl: 'Niue',
            fr: 'Niue',
        },
        rootIddCode: '+683',
        blocMemberships: [],
    },
    {
        countryCode: 'HM',
        countryISO3: 'HMD',
        i18nNames: {
            en: 'Heard Island and McDonald Islands',
            es: 'Islas Heard y McDonald',
            it: 'Isole Heard e McDonald',
            de: 'Heard und McDonaldinseln',
            pl: 'Wyspy Heard i McDonalda',
            fr: 'Îles Heard et McDonald',
        },
        rootIddCode: 'undefined',
        blocMemberships: [],
    },
    {
        countryCode: 'RW',
        countryISO3: 'RWA',
        i18nNames: {
            en: 'Rwanda',
            it: 'Ruanda',
            es: 'Ruanda',
            de: 'Ruanda',
            pl: 'Rwanda',
            fr: 'Rwanda',
        },
        rootIddCode: '+250',
        blocMemberships: [],
    },
    {
        countryCode: 'EE',
        countryISO3: 'EST',
        i18nNames: {
            en: 'Estonia',
            it: 'Estonia',
            es: 'Estonia',
            de: 'Estland',
            pl: 'Estonia',
            fr: 'Estonie',
        },
        rootIddCode: '+372',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'RO',
        countryISO3: 'ROU',
        i18nNames: {
            en: 'Romania',
            it: 'Romania',
            es: 'Rumanía',
            de: 'Rumänien',
            pl: 'Rumunia',
            fr: 'Roumanie',
        },
        rootIddCode: '+40',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'TT',
        countryISO3: 'TTO',
        i18nNames: {
            en: 'Trinidad and Tobago',
            es: 'Trinidad y Tobago',
            it: 'Trinidad e Tobago',
            de: 'Trinidad und Tobago',
            pl: 'Trynidad i Tobago',
            fr: 'Trinité-et-Tobago',
        },
        rootIddCode: '+1868',
        blocMemberships: [],
    },
    {
        countryCode: 'GY',
        countryISO3: 'GUY',
        i18nNames: {
            en: 'Guyana',
            it: 'Guyana',
            es: 'Guyana',
            de: 'Guyana',
            pl: 'Gujana',
            fr: 'Guyana',
        },
        rootIddCode: '+592',
        blocMemberships: [],
    },
    {
        countryCode: 'TL',
        countryISO3: 'TLS',
        i18nNames: {
            en: 'Timor-Leste',
            it: 'Timor Est',
            es: 'Timor-Leste',
            de: 'Osttimor',
            pl: 'Timor Wschodni',
            fr: 'Timor oriental',
        },
        rootIddCode: '+670',
        blocMemberships: [],
    },
    {
        countryCode: 'VN',
        countryISO3: 'VNM',
        i18nNames: {
            en: 'Vietnam',
            it: 'Vietnam',
            es: 'Vietnam',
            de: 'Vietnam',
            pl: 'Wietnam',
            fr: 'Vietnam',
        },
        rootIddCode: '+84',
        blocMemberships: [],
    },
    {
        countryCode: 'UY',
        countryISO3: 'URY',
        i18nNames: {
            en: 'Uruguay',
            it: 'Uruguay',
            es: 'Uruguay',
            de: 'Uruguay',
            pl: 'Urugwaj',
            fr: 'Uruguay',
        },
        rootIddCode: '+598',
        blocMemberships: [],
    },
    {
        countryCode: 'VA',
        countryISO3: 'VAT',
        i18nNames: {
            en: 'Vatican City',
            it: 'Città del Vaticano',
            es: 'Ciudad del Vaticano',
            de: 'Vatikanstadt',
            pl: 'Watykan',
            fr: 'Cité du Vatican',
        },
        rootIddCode: '+379',
        blocMemberships: [],
    },
    {
        countryCode: 'HK',
        countryISO3: 'HKG',
        i18nNames: {
            en: 'Hong Kong',
            it: 'Hong Kong',
            es: 'Hong Kong',
            de: 'Hongkong',
            pl: 'Hongkong',
            fr: 'Hong Kong',
        },
        rootIddCode: '+852',
        blocMemberships: [],
    },
    {
        countryCode: 'AT',
        countryISO3: 'AUT',
        i18nNames: {
            en: 'Austria',
            it: 'Austria',
            es: 'Austria',
            de: 'Österreich',
            pl: 'Austria',
            fr: 'Autriche',
        },
        rootIddCode: '+43',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'AG',
        countryISO3: 'ATG',
        i18nNames: {
            en: 'Antigua and Barbuda',
            es: 'Antigua y Barbuda',
            it: 'Antigua e Barbuda',
            de: 'Antigua und Barbuda',
            pl: 'Antigua i Barbuda',
            fr: 'Antigua-et-Barbuda',
        },
        rootIddCode: '+1268',
        blocMemberships: [],
    },
    {
        countryCode: 'TM',
        countryISO3: 'TKM',
        i18nNames: {
            en: 'Turkmenistan',
            it: 'Turkmenistan',
            es: 'Turkmenistán',
            de: 'Turkmenistan',
            pl: 'Turkmenistan',
            fr: 'Turkménistan',
        },
        rootIddCode: '+993',
        blocMemberships: [],
    },
    {
        countryCode: 'MZ',
        countryISO3: 'MOZ',
        i18nNames: {
            en: 'Mozambique',
            it: 'Mozambico',
            es: 'Mozambique',
            de: 'Mosambik',
            pl: 'Mozambik',
            fr: 'Mozambique',
        },
        rootIddCode: '+258',
        blocMemberships: [],
    },
    {
        countryCode: 'PA',
        countryISO3: 'PAN',
        i18nNames: {
            en: 'Panama',
            it: 'Panama',
            es: 'Panamá',
            de: 'Panama',
            pl: 'Panama',
            fr: 'Panama',
        },
        rootIddCode: '+507',
        blocMemberships: [],
    },
    {
        countryCode: 'FM',
        countryISO3: 'FSM',
        i18nNames: {
            en: 'Micronesia',
            it: 'Micronesia',
            es: 'Estados Federados de Micronesia',
            de: 'Mikronesien',
            pl: 'Mikronezja',
            fr: 'Micronésie',
        },
        rootIddCode: '+691',
        blocMemberships: [],
    },
    {
        countryCode: 'IE',
        countryISO3: 'IRL',
        i18nNames: {
            en: 'Ireland',
            it: 'Irlanda',
            es: 'Irlanda',
            de: 'Irland',
            pl: 'Irlandia',
            fr: 'Irlande',
        },
        rootIddCode: '+353',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'CW',
        countryISO3: 'CUW',
        i18nNames: {
            en: 'Curaçao',
            it: 'Curaçao',
            es: 'Curazao',
            de: 'Curaçao',
            pl: 'Curaçao',
            fr: 'Curaçao',
        },
        rootIddCode: '+599',
        blocMemberships: [],
    },
    {
        countryCode: 'GF',
        countryISO3: 'GUF',
        i18nNames: {
            en: 'French Guiana',
            it: 'Guyana francese',
            es: 'Guayana Francesa',
            de: 'Französisch-Guayana',
            pl: 'Gujana Francuska',
            fr: 'Guyane française',
        },
        rootIddCode: '+594',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'NO',
        countryISO3: 'NOR',
        i18nNames: {
            en: 'Norway',
            it: 'Norvegia',
            es: 'Noruega',
            de: 'Norwegen',
            pl: 'Norwegia',
            fr: 'Norvège',
        },
        rootIddCode: '+47',
        blocMemberships: [],
    },
    {
        countryCode: 'AX',
        countryISO3: 'ALA',
        i18nNames: {
            en: 'Åland Islands',
            it: 'Isole Aland',
            es: 'Islas Åland',
            de: 'Ålandinseln',
            pl: 'Wyspy Alandzkie',
            fr: 'Îles Åland',
        },
        rootIddCode: '+35818',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'CF',
        countryISO3: 'CAF',
        i18nNames: {
            en: 'Central African Republic',
            es: 'República Centroafricana',
            it: 'Repubblica Centrafricana',
            de: 'Zentralafrikanische Republik',
            pl: 'Republika Środkowoafrykańska',
            fr: 'République centrafricaine',
        },
        rootIddCode: '+236',
        blocMemberships: [],
    },
    {
        countryCode: 'BF',
        countryISO3: 'BFA',
        i18nNames: {
            en: 'Burkina Faso',
            it: 'Burkina Faso',
            es: 'Burkina Faso',
            de: 'Burkina Faso',
            pl: 'Burkina Faso',
            fr: 'Burkina Faso',
        },
        rootIddCode: '+226',
        blocMemberships: [],
    },
    {
        countryCode: 'ER',
        countryISO3: 'ERI',
        i18nNames: {
            en: 'Eritrea',
            it: 'Eritrea',
            es: 'Eritrea',
            de: 'Eritrea',
            pl: 'Erytrea',
            fr: 'Érythrée',
        },
        rootIddCode: '+291',
        blocMemberships: [],
    },
    {
        countryCode: 'TZ',
        countryISO3: 'TZA',
        i18nNames: {
            en: 'Tanzania',
            it: 'Tanzania',
            es: 'Tanzania',
            de: 'Tansania',
            pl: 'Tanzania',
            fr: 'Tanzanie',
        },
        rootIddCode: '+255',
        blocMemberships: [],
    },
    {
        countryCode: 'KR',
        countryISO3: 'KOR',
        i18nNames: {
            en: 'South Korea',
            it: 'Corea del Sud',
            es: 'Corea del Sur',
            de: 'Südkorea',
            pl: 'Korea Południowa',
            fr: 'Corée du Sud',
        },
        rootIddCode: '+82',
        blocMemberships: [],
    },
    {
        countryCode: 'JO',
        countryISO3: 'JOR',
        i18nNames: {
            en: 'Jordan',
            it: 'Giordania',
            es: 'Jordania',
            de: 'Jordanien',
            pl: 'Jordania',
            fr: 'Jordanie',
        },
        rootIddCode: '+962',
        blocMemberships: [],
    },
    {
        countryCode: 'MR',
        countryISO3: 'MRT',
        i18nNames: {
            en: 'Mauritania',
            it: 'Mauritania',
            es: 'Mauritania',
            de: 'Mauretanien',
            pl: 'Mauretania',
            fr: 'Mauritanie',
        },
        rootIddCode: '+222',
        blocMemberships: [],
    },
    {
        countryCode: 'LT',
        countryISO3: 'LTU',
        i18nNames: {
            en: 'Lithuania',
            it: 'Lituania',
            es: 'Lituania',
            de: 'Litauen',
            pl: 'Litwa',
            fr: 'Lituanie',
        },
        rootIddCode: '+370',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'UM',
        countryISO3: 'UMI',
        i18nNames: {
            en: 'United States Minor Outlying Islands',
            es: 'Islas menores alejadas de los Estados Unidos',
            it: "Isole minori esterne degli Stati Uniti d'America",
            de: 'Kleinere Inselbesitzungen der Vereinigten Staaten',
            pl: 'Dalekie Wyspy Mniejsze Stanów Zjednoczonych',
            fr: 'Îles mineures éloignées des États-Unis',
        },
        rootIddCode: '+268',
        blocMemberships: [],
    },
    {
        countryCode: 'SK',
        countryISO3: 'SVK',
        i18nNames: {
            en: 'Slovakia',
            it: 'Slovacchia',
            es: 'Eslovaquia',
            de: 'Slowakei',
            pl: 'Słowacja',
            fr: 'Slovaquie',
        },
        rootIddCode: '+421',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'AO',
        countryISO3: 'AGO',
        i18nNames: {
            en: 'Angola',
            it: 'Angola',
            es: 'Angola',
            de: 'Angola',
            pl: 'Angola',
            fr: 'Angola',
        },
        rootIddCode: '+244',
        blocMemberships: [],
    },
    {
        countryCode: 'KZ',
        countryISO3: 'KAZ',
        i18nNames: {
            en: 'Kazakhstan',
            it: 'Kazakistan',
            es: 'Kazajistán',
            de: 'Kasachstan',
            pl: 'Kazachstan',
            fr: 'Kazakhstan',
        },
        rootIddCode: '+77',
        blocMemberships: [],
    },
    {
        countryCode: 'MD',
        countryISO3: 'MDA',
        i18nNames: {
            en: 'Moldova',
            it: 'Moldavia',
            es: 'Moldavia',
            de: 'Moldawien',
            pl: 'Mołdawia',
            fr: 'Moldavie',
        },
        rootIddCode: '+373',
        blocMemberships: [],
    },
    {
        countryCode: 'ML',
        countryISO3: 'MLI',
        i18nNames: {
            en: 'Mali',
            it: 'Mali',
            es: 'Malí',
            de: 'Mali',
            pl: 'Mali',
            fr: 'Mali',
        },
        rootIddCode: '+223',
        blocMemberships: [],
    },
    {
        countryCode: 'FK',
        countryISO3: 'FLK',
        i18nNames: {
            en: 'Falkland Islands',
            es: 'Islas Malvinas',
            it: 'Isole Falkland o Isole Malvine',
            de: 'Falklandinseln',
            pl: 'Wyspy Falklandzkie',
            fr: 'Îles Falkland',
        },
        rootIddCode: '+500',
        blocMemberships: [],
    },
    {
        countryCode: 'AM',
        countryISO3: 'ARM',
        i18nNames: {
            en: 'Armenia',
            it: 'Armenia',
            es: 'Armenia',
            de: 'Armenien',
            pl: 'Armenia',
            fr: 'Arménie',
        },
        rootIddCode: '+374',
        blocMemberships: [],
    },
    {
        countryCode: 'WS',
        countryISO3: 'WSM',
        i18nNames: {
            en: 'Samoa',
            it: 'Samoa',
            es: 'Samoa',
            de: 'Samoa',
            pl: 'Samoa',
            fr: 'Samoa',
        },
        rootIddCode: '+685',
        blocMemberships: [],
    },
    {
        countryCode: 'JE',
        countryISO3: 'JEY',
        i18nNames: {
            en: 'Jersey',
            it: 'Isola di Jersey',
            es: 'Jersey',
            de: 'Jersey',
            pl: 'Jersey',
            fr: 'Jersey',
        },
        rootIddCode: '+44',
        blocMemberships: [],
    },
    {
        countryCode: 'JP',
        countryISO3: 'JPN',
        i18nNames: {
            en: 'Japan',
            it: 'Giappone',
            es: 'Japón',
            de: 'Japan',
            pl: 'Japonia',
            fr: 'Japon',
        },
        rootIddCode: '+81',
        blocMemberships: [],
    },
    {
        countryCode: 'BO',
        countryISO3: 'BOL',
        i18nNames: {
            en: 'Bolivia',
            it: 'Bolivia',
            es: 'Bolivia',
            de: 'Bolivien',
            pl: 'Boliwia',
            fr: 'Bolivie',
        },
        rootIddCode: '+591',
        blocMemberships: [],
    },
    {
        countryCode: 'CL',
        countryISO3: 'CHL',
        i18nNames: {
            en: 'Chile',
            it: 'Cile',
            es: 'Chile',
            de: 'Chile',
            pl: 'Chile',
            fr: 'Chili',
        },
        rootIddCode: '+56',
        blocMemberships: [],
    },
    {
        countryCode: 'US',
        countryISO3: 'USA',
        i18nNames: {
            en: 'United States',
            it: "Stati Uniti d'America",
            es: 'Estados Unidos',
            de: 'Vereinigte Staaten',
            pl: 'Stany Zjednoczone',
            fr: 'États-Unis',
        },
        rootIddCode: '+1989',
        blocMemberships: [],
    },
    {
        countryCode: 'VC',
        countryISO3: 'VCT',
        i18nNames: {
            en: 'Saint Vincent and the Grenadines',
            es: 'San Vicente y las Granadinas',
            it: 'Saint Vincent e Grenadine',
            de: 'St. Vincent und die Grenadinen',
            pl: 'Saint Vincent i Grenadyny',
            fr: 'Saint-Vincent-et-les-Grenadines',
        },
        rootIddCode: '+1784',
        blocMemberships: [],
    },
    {
        countryCode: 'BM',
        countryISO3: 'BMU',
        i18nNames: {
            en: 'Bermuda',
            it: 'Bermuda',
            es: 'Bermudas',
            de: 'Bermuda',
            pl: 'Bermudy',
            fr: 'Bermudes',
        },
        rootIddCode: '+1441',
        blocMemberships: [],
    },
    {
        countryCode: 'SC',
        countryISO3: 'SYC',
        i18nNames: {
            en: 'Seychelles',
            it: 'Seychelles',
            es: 'Seychelles',
            de: 'Seychellen',
            pl: 'Seszele',
            fr: 'Seychelles',
        },
        rootIddCode: '+248',
        blocMemberships: [],
    },
    {
        countryCode: 'IO',
        countryISO3: 'IOT',
        i18nNames: {
            en: 'British Indian Ocean Territory',
            es: 'Territorio Británico del Océano Índico',
            it: "Territorio britannico dell'oceano indiano",
            de: 'Britisches Territorium im Indischen Ozean',
            pl: 'Brytyjskie Terytorium Oceanu Indyjskiego',
            fr: "Territoire britannique de l'océan Indien",
        },
        rootIddCode: '+246',
        blocMemberships: [],
    },
    {
        countryCode: 'GT',
        countryISO3: 'GTM',
        i18nNames: {
            en: 'Guatemala',
            it: 'Guatemala',
            es: 'Guatemala',
            de: 'Guatemala',
            pl: 'Gwatemala',
            fr: 'Guatemala',
        },
        rootIddCode: '+502',
        blocMemberships: [],
    },
    {
        countryCode: 'EC',
        countryISO3: 'ECU',
        i18nNames: {
            en: 'Ecuador',
            it: 'Ecuador',
            es: 'Ecuador',
            de: 'Ecuador',
            pl: 'Ekwador',
            fr: 'Équateur',
        },
        rootIddCode: '+593',
        blocMemberships: [],
    },
    {
        countryCode: 'MQ',
        countryISO3: 'MTQ',
        i18nNames: {
            en: 'Martinique',
            it: 'Martinica',
            es: 'Martinica',
            de: 'Martinique',
            pl: 'Martynika',
            fr: 'Martinique',
        },
        rootIddCode: '+596',
        blocMemberships: [],
    },
    {
        countryCode: 'TJ',
        countryISO3: 'TJK',
        i18nNames: {
            en: 'Tajikistan',
            it: 'Tagikistan',
            es: 'Tayikistán',
            de: 'Tadschikistan',
            pl: 'Tadżykistan',
            fr: 'Tadjikistan',
        },
        rootIddCode: '+992',
        blocMemberships: [],
    },
    {
        countryCode: 'MT',
        countryISO3: 'MLT',
        i18nNames: {
            en: 'Malta',
            it: 'Malta',
            es: 'Malta',
            de: 'Malta',
            pl: 'Malta',
            fr: 'Malte',
        },
        rootIddCode: '+356',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'GM',
        countryISO3: 'GMB',
        i18nNames: {
            en: 'Gambia',
            it: 'Gambia',
            es: 'Gambia',
            de: 'Gambia',
            pl: 'Gambia',
            fr: 'Gambie',
        },
        rootIddCode: '+220',
        blocMemberships: [],
    },
    {
        countryCode: 'NG',
        countryISO3: 'NGA',
        i18nNames: {
            en: 'Nigeria',
            it: 'Nigeria',
            es: 'Nigeria',
            de: 'Nigeria',
            pl: 'Nigeria',
            fr: 'Nigeria',
        },
        rootIddCode: '+234',
        blocMemberships: [],
    },
    {
        countryCode: 'BS',
        countryISO3: 'BHS',
        i18nNames: {
            en: 'Bahamas',
            it: 'Bahamas',
            es: 'Bahamas',
            de: 'Bahamas',
            pl: 'Bahamy',
            fr: 'Bahamas',
        },
        rootIddCode: '+1242',
        blocMemberships: [],
    },
    {
        countryCode: 'XK',
        countryISO3: 'UNK',
        i18nNames: {
            en: 'Kosovo',
            it: 'Kosovo',
            es: 'Kosovo',
            de: 'Kosovo',
            pl: 'Kosowo',
            fr: 'Kosovo',
        },
        rootIddCode: '+383',
        blocMemberships: [],
    },
    {
        countryCode: 'KW',
        countryISO3: 'KWT',
        i18nNames: {
            en: 'Kuwait',
            it: 'Kuwait',
            es: 'Kuwait',
            de: 'Kuwait',
            pl: 'Kuwejt',
            fr: 'Koweït',
        },
        rootIddCode: '+965',
        blocMemberships: [],
    },
    {
        countryCode: 'MV',
        countryISO3: 'MDV',
        i18nNames: {
            en: 'Maldives',
            it: 'Maldive',
            es: 'Maldivas',
            de: 'Malediven',
            pl: 'Malediwy',
            fr: 'Maldives',
        },
        rootIddCode: '+960',
        blocMemberships: [],
    },
    {
        countryCode: 'SS',
        countryISO3: 'SSD',
        i18nNames: {
            en: 'South Sudan',
            it: 'Sudan del sud',
            es: 'Sudán del Sur',
            de: 'Südsudan',
            pl: 'Sudan Południowy',
            fr: 'Soudan du Sud',
        },
        rootIddCode: '+211',
        blocMemberships: [],
    },
    {
        countryCode: 'IR',
        countryISO3: 'IRN',
        i18nNames: {
            en: 'Iran',
            it: 'Iran',
            es: 'Irán',
            de: 'Iran',
            pl: 'Iran',
            fr: 'Iran',
        },
        rootIddCode: '+98',
        blocMemberships: [],
    },
    {
        countryCode: 'AL',
        countryISO3: 'ALB',
        i18nNames: {
            en: 'Albania',
            it: 'Albania',
            es: 'Albania',
            de: 'Albanien',
            pl: 'Albania',
            fr: 'Albanie',
        },
        rootIddCode: '+355',
        blocMemberships: [],
    },
    {
        countryCode: 'BR',
        countryISO3: 'BRA',
        i18nNames: {
            en: 'Brazil',
            it: 'Brasile',
            es: 'Brasil',
            de: 'Brasilien',
            pl: 'Brazylia',
            fr: 'Brésil',
        },
        rootIddCode: '+55',
        blocMemberships: [],
    },
    {
        countryCode: 'RS',
        countryISO3: 'SRB',
        i18nNames: {
            en: 'Serbia',
            it: 'Serbia',
            es: 'Serbia',
            de: 'Serbien',
            pl: 'Serbia',
            fr: 'Serbie',
        },
        rootIddCode: '+381',
        blocMemberships: [],
    },
    {
        countryCode: 'BZ',
        countryISO3: 'BLZ',
        i18nNames: {
            en: 'Belize',
            it: 'Belize',
            es: 'Belice',
            de: 'Belize',
            pl: 'Belize',
            fr: 'Belize',
        },
        rootIddCode: '+501',
        blocMemberships: [],
    },
    {
        countryCode: 'MM',
        countryISO3: 'MMR',
        i18nNames: {
            en: 'Myanmar',
            it: 'Birmania',
            es: 'Birmania',
            de: 'Myanmar',
            pl: 'Mjanma',
            fr: 'Birmanie',
        },
        rootIddCode: '+95',
        blocMemberships: [],
    },
    {
        countryCode: 'BT',
        countryISO3: 'BTN',
        i18nNames: {
            en: 'Bhutan',
            it: 'Bhutan',
            es: 'Bután',
            de: 'Bhutan',
            pl: 'Bhutan',
            fr: 'Bhoutan',
        },
        rootIddCode: '+975',
        blocMemberships: [],
    },
    {
        countryCode: 'VE',
        countryISO3: 'VEN',
        i18nNames: {
            en: 'Venezuela',
            it: 'Venezuela',
            es: 'Venezuela',
            de: 'Venezuela',
            pl: 'Wenezuela',
            fr: 'Venezuela',
        },
        rootIddCode: '+58',
        blocMemberships: [],
    },
    {
        countryCode: 'LR',
        countryISO3: 'LBR',
        i18nNames: {
            en: 'Liberia',
            it: 'Liberia',
            es: 'Liberia',
            de: 'Liberia',
            pl: 'Liberia',
            fr: 'Libéria',
        },
        rootIddCode: '+231',
        blocMemberships: [],
    },
    {
        countryCode: 'JM',
        countryISO3: 'JAM',
        i18nNames: {
            en: 'Jamaica',
            it: 'Giamaica',
            es: 'Jamaica',
            de: 'Jamaika',
            pl: 'Jamajka',
            fr: 'Jamaïque',
        },
        rootIddCode: '+1876',
        blocMemberships: [],
    },
    {
        countryCode: 'PL',
        countryISO3: 'POL',
        i18nNames: {
            en: 'Poland',
            it: 'Polonia',
            es: 'Polonia',
            de: 'Polen',
            pl: 'Polska',
            fr: 'Pologne',
        },
        rootIddCode: '+48',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'KY',
        countryISO3: 'CYM',
        i18nNames: {
            en: 'Cayman Islands',
            it: 'Isole Cayman',
            es: 'Islas Caimán',
            de: 'Kaimaninseln',
            pl: 'Kajmany',
            fr: 'Îles Caïmans',
        },
        rootIddCode: '+1345',
        blocMemberships: [],
    },
    {
        countryCode: 'BN',
        countryISO3: 'BRN',
        i18nNames: {
            en: 'Brunei',
            it: 'Brunei',
            es: 'Brunéi',
            de: 'Brunei',
            pl: 'Brunei',
            fr: 'Brunei',
        },
        rootIddCode: '+673',
        blocMemberships: [],
    },
    {
        countryCode: 'KM',
        countryISO3: 'COM',
        i18nNames: {
            en: 'Comoros',
            it: 'Comore',
            es: 'Comoras',
            de: 'Komoren',
            pl: 'Komory',
            fr: 'Comores',
        },
        rootIddCode: '+269',
        blocMemberships: [],
    },
    {
        countryCode: 'GU',
        countryISO3: 'GUM',
        i18nNames: {
            en: 'Guam',
            it: 'Guam',
            es: 'Guam',
            de: 'Guam',
            pl: 'Guam',
            fr: 'Guam',
        },
        rootIddCode: '+1671',
        blocMemberships: [],
    },
    {
        countryCode: 'TO',
        countryISO3: 'TON',
        i18nNames: {
            en: 'Tonga',
            it: 'Tonga',
            es: 'Tonga',
            de: 'Tonga',
            pl: 'Tonga',
            fr: 'Tonga',
        },
        rootIddCode: '+676',
        blocMemberships: [],
    },
    {
        countryCode: 'KI',
        countryISO3: 'KIR',
        i18nNames: {
            en: 'Kiribati',
            it: 'Kiribati',
            es: 'Kiribati',
            de: 'Kiribati',
            pl: 'Kiribati',
            fr: 'Kiribati',
        },
        rootIddCode: '+686',
        blocMemberships: [],
    },
    {
        countryCode: 'GH',
        countryISO3: 'GHA',
        i18nNames: {
            en: 'Ghana',
            it: 'Ghana',
            es: 'Ghana',
            de: 'Ghana',
            pl: 'Ghana',
            fr: 'Ghana',
        },
        rootIddCode: '+233',
        blocMemberships: [],
    },
    {
        countryCode: 'TD',
        countryISO3: 'TCD',
        i18nNames: {
            en: 'Chad',
            it: 'Ciad',
            es: 'Chad',
            de: 'Tschad',
            pl: 'Czad',
            fr: 'Tchad',
        },
        rootIddCode: '+235',
        blocMemberships: [],
    },
    {
        countryCode: 'ZW',
        countryISO3: 'ZWE',
        i18nNames: {
            en: 'Zimbabwe',
            it: 'Zimbabwe',
            es: 'Zimbabue',
            de: 'Simbabwe',
            pl: 'Zimbabwe',
            fr: 'Zimbabwe',
        },
        rootIddCode: '+263',
        blocMemberships: [],
    },
    {
        countryCode: 'MF',
        countryISO3: 'MAF',
        i18nNames: {
            en: 'Saint Martin',
            it: 'Saint Martin',
            es: 'San Martín (parte francesa)',
            de: 'Saint-Martin',
            pl: 'Saint-Martin',
            fr: 'Saint-Martin',
        },
        rootIddCode: '+590',
        blocMemberships: [],
    },
    {
        countryCode: 'MN',
        countryISO3: 'MNG',
        i18nNames: {
            en: 'Mongolia',
            it: 'Mongolia',
            es: 'Mongolia',
            de: 'Mongolei',
            pl: 'Mongolia',
            fr: 'Mongolie',
        },
        rootIddCode: '+976',
        blocMemberships: [],
    },
    {
        countryCode: 'PT',
        countryISO3: 'PRT',
        i18nNames: {
            en: 'Portugal',
            it: 'Portogallo',
            es: 'Portugal',
            de: 'Portugal',
            pl: 'Portugalia',
            fr: 'Portugal',
        },
        rootIddCode: '+351',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'AS',
        countryISO3: 'ASM',
        i18nNames: {
            en: 'American Samoa',
            it: 'Samoa Americane',
            es: 'Samoa Americana',
            de: 'Amerikanisch-Samoa',
            pl: 'Samoa Amerykańskie',
            fr: 'Samoa américaines',
        },
        rootIddCode: '+1684',
        blocMemberships: [],
    },
    {
        countryCode: 'CG',
        countryISO3: 'COG',
        i18nNames: {
            en: 'Republic of the Congo',
            it: 'Congo',
            es: 'República del Congo',
            de: 'Republik Kongo',
            pl: 'Kongo',
            fr: 'République du Congo',
        },
        rootIddCode: '+242',
        blocMemberships: [],
    },
    {
        countryCode: 'BE',
        countryISO3: 'BEL',
        i18nNames: {
            en: 'Belgium',
            it: 'Belgio',
            es: 'Bélgica',
            de: 'Belgien',
            pl: 'Belgia',
            fr: 'Belgique',
        },
        rootIddCode: '+32',
        blocMemberships: ['EU'],
    },
    {
        countryCode: 'IL',
        countryISO3: 'ISR',
        i18nNames: {
            en: 'Israel',
            it: 'Israele',
            es: 'Israel',
            de: 'Israel',
            pl: 'Izrael',
            fr: 'Israël',
        },
        rootIddCode: '+972',
        blocMemberships: [],
    },
    {
        countryCode: 'NZ',
        countryISO3: 'NZL',
        i18nNames: {
            en: 'New Zealand',
            it: 'Nuova Zelanda',
            es: 'Nueva Zelanda',
            de: 'Neuseeland',
            pl: 'Nowa Zelandia',
            fr: 'Nouvelle-Zélande',
        },
        rootIddCode: '+64',
        blocMemberships: [],
    },
    {
        countryCode: 'NI',
        countryISO3: 'NIC',
        i18nNames: {
            en: 'Nicaragua',
            it: 'Nicaragua',
            es: 'Nicaragua',
            de: 'Nicaragua',
            pl: 'Nikaragua',
            fr: 'Nicaragua',
        },
        rootIddCode: '+505',
        blocMemberships: [],
    },
    {
        countryCode: 'AI',
        countryISO3: 'AIA',
        i18nNames: {
            en: 'Anguilla',
            it: 'Anguilla',
            es: 'Anguila',
            de: 'Anguilla',
            pl: 'Anguilla',
            fr: 'Anguilla',
        },
        rootIddCode: '+1264',
        blocMemberships: [],
    },
];
